/** @format */

export default function useJump(id: string, index: number) {
	const returnEle = document.querySelector(`#${id}`); //productId是将要跳转区域的id
	if (returnEle) {
		returnEle.scrollIntoView(); // true 是默认的
	}
	if (index > 0) {
		let _self = document.getElementById(`jumpTitle${index}`);
		if (_self) {
			let parent = _self.parentNode;
			let children = parent?.children;
			if (children && children.length > 0) {
				for (let i = 0; i < children.length; i++) {
					children[i].className = '';
				}
			}
			_self.className = 'active';
		}
	}
}
