/** @format */

import http from '../js/http-client';

interface PostData {
	[propName: string]: any;
}

class UserApi {
	/**
	 * 用户登录
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	login(data: PostData) {
		return http.request<null>({
			url: '/api/user/login',
			method: 'post',
			data,
		});
	}

	/**
	 * 获取手机验证码
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	sendCode(data: PostData) {
		return http.request<null>({
			url: '/api/sms/send-sms-verification-code',
			method: 'post',
			data,
		});
	}

	/**
	 * 添加用户信息
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	addUserMsg(data: PostData) {
		return http.request<null>({
			url: '/api/user/user',
			method: 'post',
			data,
		});
	}

	/**
	 * 添加用户企业信息
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	addCompanyMsg(data: PostData) {
		return http.request<null>({
			url: '/api/company/company',
			method: 'post',
			data,
		});
	}

	/**
	 * 添加用户企业开户行信息
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	addBankMsg(data: PostData) {
		return http.request<null>({
			url: '/api/user/user-bank',
			method: 'post',
			data,
		});
	}

	/**
	 * 添加用户企业法人信息
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	addCorporateor(data: PostData) {
		return http.request<null>({
			url: '/api/company/corporateor',
			method: 'post',
			data,
		});
	}

	/**
	 * 添加用户企业法人信息
	 * @param {any} data:PostData
	 * @returns {Promise}
	 */
	addQuestionary(data: PostData) {
		return http.request<null>({
			url: '/api/company/company-investigation',
			method: 'post',
			data,
		});
	}
}

const userApi = new UserApi();

export default userApi;
